let domain= "https://airbus-dev-10874-2j17.auth.eu-west-1.amazoncognito.com";
let client_id= "7407ignhoe32jbosinen9kt252";
let client_secret= "1jmp4i4evd3aus8biotkbtihqe9ld2dplic8srvha8i6bb1mida7";
let redirect_uri= "https://sde.dev.services.2j17.aws.airbus-v.cloud";
let api_domain= "https://94ksrtjcpe.execute-api.eu-west-1.amazonaws.com/dev";
let x_api_key= "Pw5K1BW1M98aBYnJ9paZz7metTq72bD44VbPDhAo";
let sas_env= "DEV";
let sas_url= "https://94ksrtjcpe.execute-api.eu-west-1.amazonaws.com/dev";
let sas_x_api_key= "zAiSOBF0iJ1TUKZHQiAeF923DEZgVEi641wjs46w";
let dataset_path= "https://core.skywise.com/workspace/data-integration/dataset/preview";
let s3_private= "https://d2kba87yqxsshs.cloudfront.net/private";
let oauth_client_id= "9095ec157c903fb32a93401dcb6b0b08";
let oauth_client_secret= "2a84d9a9a03fe9a5fc9afc3bd54cfe82";
let oauth_redirect_url= "https://sde.dev.services.2j17.aws.airbus-v.cloud/SDE/ExportDS";
let skywise_oauth = 'https://core.skywise.com/foundry-multipass/api/oauth2';
switch (process.env.REACT_APP_ENV) {
  case 'dev':
    domain= "https://airbus-dev-10874-2j17.auth.eu-west-1.amazoncognito.com";
    client_id= "7407ignhoe32jbosinen9kt252";
    client_secret= "1jmp4i4evd3aus8biotkbtihqe9ld2dplic8srvha8i6bb1mida7";
    redirect_uri= "https://sde.dev.services.2j17.aws.airbus-v.cloud";
    api_domain= "https://94ksrtjcpe.execute-api.eu-west-1.amazonaws.com/dev";
    x_api_key= "Pw5K1BW1M98aBYnJ9paZz7metTq72bD44VbPDhAo";
    sas_env= "PROD";
    sas_url= "https://m2b7wzo6uc.execute-api.eu-west-1.amazonaws.com/prod";
    sas_x_api_key= "zAiSOBF0iJ1TUKZHQiAeF923DEZgVEi641wjs46w";
    dataset_path= "https://core.skywise.com/workspace/data-integration/dataset/preview";
    s3_private= "https://d2kba87yqxsshs.cloudfront.net/private";
    oauth_client_id= "9095ec157c903fb32a93401dcb6b0b08";
    oauth_client_secret= "2a84d9a9a03fe9a5fc9afc3bd54cfe82";
    oauth_redirect_url= "https://sde.dev.services.2j17.aws.airbus-v.cloud/SDE/ExportDS"
    break;
  case 'staging':
    domain = "https://airbus-stage-10919-2j17.auth.eu-west-1.amazoncognito.com";
    client_id = "41ddblvjqcld1ur8ml2hpjcqc9";
    client_secret = "t2mf5ndp9qnpgi8s3r7tpq9q6f252nt1p9kcqst46te2f1dtr7j";
    redirect_uri = "https://sde.staging.services.2j17-pub.aws.airbus-v.cloud";
    api_domain = "https://d621qv7e85.execute-api.eu-west-1.amazonaws.com/staging";
    sas_url = "https://m2b7wzo6uc.execute-api.eu-west-1.amazonaws.com/prod";
    dataset_path = "https://core.skywise.com/workspace/data-integration/dataset/preview";
    sas_env = "PROD";
    s3_private = "https://d1c602v012glqz.cloudfront.net/private";
    x_api_key = "AalRHH4Pom97tvwI477Uo5uTCS8LOlwN3q6xAWdc";
    sas_x_api_key = "zAiSOBF0iJ1TUKZHQiAeF923DEZgVEi641wjs46w";
    oauth_client_id = "01360217137296287bfd49803a656f99";
    oauth_client_secret = "923553515a6562c2f584c4037694a3b9";
    oauth_redirect_url = "https://sde.staging.services.2j17-pub.aws.airbus-v.cloud/SDE/ExportDS";
    break;
  case 'prod':
    domain = "https://airbus-prod-10916-2j17.auth.eu-west-1.amazoncognito.com";
    client_id = "7vv64nmgubv09k0kd1qjqd6r1b";
    client_secret = "asdpedqe7nrp2kngr34ll8cao2rrb7r0brmf3nu1t6n44mtamto";
    redirect_uri = "https://data.fomax.skywise.com";
    api_domain = "https://m2b7wzo6uc.execute-api.eu-west-1.amazonaws.com/prod";
    sas_url = "https://m2b7wzo6uc.execute-api.eu-west-1.amazonaws.com/prod";
    dataset_path = "https://core.skywise.com/workspace/data-integration/dataset/preview";
    sas_env = "PROD";
    s3_private = "https://d3qv9yb1nf82id.cloudfront.net/private";
    x_api_key = "zAiSOBF0iJ1TUKZHQiAeF923DEZgVEi641wjs46w";
    sas_x_api_key = "zAiSOBF0iJ1TUKZHQiAeF923DEZgVEi641wjs46w";
    oauth_client_id = "75e09e73727d946d0fa5b90780ea1ba6";
    oauth_client_secret  = "ab5e47bd90fa399118b7bffc698b6a24";
    oauth_redirect_url =  "https://data.fomax.skywise.com/SDE/ExportDS";
    break;
  default:
    break;
}

export const DOMAIN = domain;
export const CLIENT_ID = client_id;
export const CLIENT_SECRET = client_secret;
export const REDIRECT_URI = redirect_uri;
export const API_DOMAIN = api_domain;
export const SAS_URL = sas_url;
export const DATASET_PATH = dataset_path;
export const SAS_ENV = sas_env;
export const S3_PRIVATE = s3_private;
export const X_API_KEY = x_api_key;
export const SAS_X_API_KEY = sas_x_api_key;
export const SKYWISE_OAUTH = skywise_oauth;
export const OAUTH_REDIRECT_URI = oauth_redirect_url;
export const OAUTH_CLIENT_ID = oauth_client_id;
export const OAUTH_CLIENT_SECRET = oauth_client_secret;

